import React from 'react';
import { PageProps } from 'gatsby';

import Banner from '../components/Hero/Banner';
import Layout from '../layouts/Layout';
// @ts-ignore
import backArrow from '../images/red-arrow-left.svg';

import './jobDescription.css';

const JobDescription = ({ pageContext }: any) => {
	console.log(pageContext);
	return (
		<Layout
			title={'Dirt Cheap | Employment Opportunities'}
			desc={'Dirt Cheap | Employment Opportunities'}
		>
			<Banner />
			<section>
				<div className="mx-auto my-0 w-full max-w-[1176px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
					<div className="w-full max-md:px-[16px]">
						<div className="w-full pt-[48px] pb-[80px] text-left max-md:pb-10 max-md:pt-[32px]">
							<a
								href="/search"
								className="text-[20px] text-dirtcheaptext hover:text-dirtcheaptext"
							>
								<button className="flex flex-row items-center">
									<img
										src={backArrow}
										width={16}
										className="mr-[6px]"
									/>
									Back
								</button>
							</a>
							<h2 className="font-oswald text-[48px] font-bold uppercase text-dirtcheaptext max-md:text-[30px]">
								{pageContext.title}
							</h2>
						</div>
					</div>
					<div className="flex flex-row px-[16px] pb-[160px] max-lg:flex-col-reverse">
						<div className="flex w-70 flex-col items-start justify-start max-lg:w-full">
							<h3 className="font-oswald text-[30px] text-dirtcheaptext">
								JOB DESCRIPTION
							</h3>

							<div className="pb-[64px]">
								<p
									dangerouslySetInnerHTML={{
										__html: pageContext.description
									}}
								/>
							</div>

							<a
								className="ApplyButton ApplyButtonBottom"
								href={pageContext.url}
								target={'_blank'}
							>
								<button className="h-[44px] w-full w-[208px] rounded bg-dirtcheaptext font-source-sans-pro text-[22px] font-bold uppercase leading-[28px] text-white">
									APPLY NOW
								</button>
							</a>
						</div>
						<div className="ml-[36px] flex w-30 flex-col items-start justify-start max-lg:ml-0 max-lg:mb-[24px] max-lg:w-full">
							<div className="w-full border-t-4 border-dirtcheaptext bg-[#F4F4F4] px-[24px] py-9">
								<p className="c-[#333333] font-source-sans-pro text-[18px] font-bold uppercase leading-[28px]">
									Location
								</p>
								<p className="c-[#333333] pb-[20px] font-source-sans-pro text-[18px] leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</p>
								<p className="c-[#333333]  font-source-sans-pro text-[18px] font-bold uppercase leading-[28px]">
									POSTING DATE
								</p>
								<p className="c-[#333333] pb-[20px] font-source-sans-pro text-[18px] leading-[28px] max-lg:pb-2">
									{pageContext.postingDate}
								</p>
								<div className="w-full pt-[28px] text-center max-lg:text-left">
									<a
										className="ApplyButton ApplyButtonTop"
										href={pageContext.url}
										target={'_blank'}
									>
										<button className="h-[44px] w-full w-[208px] rounded bg-dirtcheaptext font-source-sans-pro text-[22px] font-bold uppercase leading-[28px] text-white">
											APPLY NOW
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
